import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import styling from '../../styling';
import { MDXRenderer } from 'gatsby-plugin-mdx';

const StyledLink = styled(Link)`
  color: ${styling.colors.text.body};

  &:last-child {
    section {
      padding-bottom: 2em;
    }

    hr {
      display: none;
    }
  }
`;

const StyledSection = styled.section`
  margin: 0 -1em;
  padding: 1.618em 1em 0 1em;
  border-radius: ${styling.sizes.borderRadius};
  transition: ${styling.miscellaneous.transition};

  h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.25rem;
    color: ${styling.colors.text.headline};
    line-height: 1.25;
  }

  p {
    margin: 0;
  }

  hr {
    margin: 1.618em 4em 0 2em;
    border: none;
    border-bottom: 1px solid ${styling.colors.primary.L80};
  }

  &:hover {
    background-color: ${styling.colors.primary.L95};
  }
`;

const StyledExtraInformation = styled.div`
  display: flex;
  color: ${styling.colors.gray.L54};
  line-height: 1;

  > small {
    font-size: 0.9em;

    :first-child {
      margin-right: 0.5rem;
    }
  }
`;

const StyledSeriesSummary = styled.div`
  margin-bottom: 1em;
`;

interface Props {
  article: ArticleOverview;
  series?: Series;
}

const ArticleOverview = ({ article, series }: Props) => (
  <StyledLink to={`/blogposts/${article.frontmatter.slug}`}>
    <StyledSection>
      <StyledExtraInformation>
        <small>{article.frontmatter.date}</small>
        <small>({article.timeToRead} min)</small>
      </StyledExtraInformation>
      <h2>
        {series != null ? (
          <Fragment>
            {series.frontmatter.title}:&nbsp;
            {article.frontmatter.title}
          </Fragment>
        ) : (
          article.frontmatter.title
        )}
      </h2>
      {series != null && <StyledSeriesSummary dangerouslySetInnerHTML={{ __html: series.html }} />}
      <hr />
    </StyledSection>
  </StyledLink>
);

export default ArticleOverview;
