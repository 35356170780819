import { graphql, Link } from 'gatsby';
import BlogsPage from '../components/blogposts';

export default BlogsPage;

export const query = graphql`
{
  blogposts: allMdx(
    sort: {fields: [frontmatter___date], order: DESC},
    filter: {
      fileAbsolutePath: {regex: "/Blogpost \\d+ - .*/blogpost.md/"},
      frontmatter: {draft: {eq: false}, obsolete: {eq: false}}
    }
  ) {
    totalCount
    edges {
      node {
        timeToRead
        frontmatter {
          title
          tags
          date
          lastmod
          draft
          obsolete
          slug
          slugSynonyms
        }
      }
    }
  }
  series: allMdx(
    filter: {
      fileAbsolutePath: { regex: "/Series/.*\\.md/" }
    }
  ) {
    edges {
      node {
        body
        frontmatter {
          title
          tags
          draft
          obsolete
          blogpostSlugs
        }
      }
    }
  }
}
`;
