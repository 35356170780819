import React from 'react';
import ArticleOverview from './articleOverview';
import Layout from '../layout';
import styled from 'styled-components';
import { Page } from '../layout/header';
import { getSeriesForArticle } from '../../utils';

const StyledMain = styled.main`
  padding: 1em 0;
`;

interface Props {
  location: Location;
  data: {
    blogposts: {
      totalCount: number;
      edges: {
        node: ArticleOverview;
      }[];
    };
    series: {
      edges: {
        node: Series;
      }[];
    };
  };
}

const BlogsPage = (props: Props) => {
  return (
    <Layout title="Blog" currentPage={Page.Blog} location={props.location} canonicalUrl="/">
      <StyledMain>
        {props.data != null &&
          props.data.blogposts.edges.map((edge) => (
            <ArticleOverview
              article={edge.node}
              series={getSeriesForArticle(
                edge.node,
                props.data.series.edges.map((e) => e.node)
              )}
            />
          ))}
      </StyledMain>
    </Layout>
  );
};

export default BlogsPage;
